/* eslint-disable react/no-danger */
import React from 'react';
import Layout from 'gatsby-theme-carbon/src/templates/Default';

const htmlContent = require('../../public/files/privacy.html');

const frontmatter = {
  label: 'Privacy policy',
  title: 'Privacy',
  description: 'Privacy policy',
};

const LibraryPage = ({ pageContext, ...rest }) => {
  console.log(pageContext);
  console.log(rest);

  return (
  <Layout pageContext={{ ...pageContext, frontmatter }} {...rest}>
    <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
  </Layout>
  )
  }

export default LibraryPage;
